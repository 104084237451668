import { CiHeart } from "react-icons/ci";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiMinus, FiPlus } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAllProductByIdAsync, selectProductById } from "./productSlice";
import { Rating, Stack, LinearProgress, Typography, Box } from "@mui/material";
import axios from "axios";
import { selectCartProduct, setAddToCart } from "../Cart/cartProductSlice";
import { selectWishlistProduct, setAddToWishlist } from "../Cart/wishlistSlice";

export default function ProductOverview() {
  const dispatch = useDispatch();
  const params = useParams();
  const product = useSelector(selectProductById);
  const cartProductSlice = useSelector(selectCartProduct);
  const wishlistProduct = useSelector(selectWishlistProduct);

  const apiUrl = process.env.REACT_APP_API_URL;

  const [productImages, setProductImages] = useState([]);
  const [isProductLoaded, setIsProductLoaded] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchAllProductByIdAsync(params.productId.toString()));
  }, [dispatch, params.productId]);

  useEffect(() => {
    localStorage.setItem("wishlist_product", JSON.stringify(wishlistProduct));
  }, [wishlistProduct]);

  useEffect(() => {
    localStorage.setItem("cart_product", JSON.stringify(cartProductSlice));
  }, [cartProductSlice]);

  useEffect(() => {
    if (product && product.success) {
      setIsProductLoaded(true);
    }

    axios
      .get(`${apiUrl}/auth/productImages/${params.productId}`)
      .then((res) => {
        if (res.data.success) {
          const imagePaths = res.data.images.map(
            (imageObject) => imageObject.image
          );
          setProductImages(imagePaths);
          setSelectedImageIndex(0);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, [params.productId, product]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  if (!isProductLoaded) {
    return <div>Loading...</div>;
  }

  const largeImageSrc = `https://www.upcharayurveda.com/${productImages[
    selectedImageIndex
  ]?.replace(/^\.\.\//, "")}`;

  const handleAddToCart = (e) => {
    dispatch(setAddToCart(product.data));
  };

  const handleAddToWishlist = (e) => {
    dispatch(setAddToWishlist(product.data));
    localStorage.setItem(
      "wishlist_product",
      JSON.stringify([...wishlistProduct, product.data])
    );
  };

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="lg:flex lg:items-start lg:space-x-5">
          {/* Large Image */}
          {productImages.length > 0 && (
            <div className="lg:w-1/2 lg:mr-8 relative mb-5">
              <img
                src={largeImageSrc}
                alt={`Product Image ${selectedImageIndex + 1}`}
                className="w-full h-[24rem] object-center object-contain rounded-lg mb-6 sm:mb-0"
              />

              {/* Image gallery (conditionally rendered for screen size) */}
              <div className="hidden md:flex md:flex-row gap-4 mt-4 mb-5 overflow-x-auto">
                {productImages.map((image, index) => (
                  <img
                    key={index}
                    onClick={() => handleImageClick(index)}
                    src={`https://www.upcharayurveda.com/${image.replace(
                      /^\.\.\//,
                      ""
                    )}`}
                    alt={`Product Image ${index + 1}`}
                    className={`h-24 w-full object-cover rounded cursor-pointer ${
                      selectedImageIndex === index
                        ? "border-2 border-indigo-500"
                        : ""
                    }`}
                    style={{ flexBasis: `calc(25% - 8px)` }}
                  />
                ))}
              </div>
            </div>

            // <div className="lg:w-1/2 lg:mr-8 relative mb-5">
            //   <div className="w-full h-[24rem] bg-gray-200 rounded-lg mb-6 sm:mb-0 flex items-center justify-center">
            //     <img
            //       src={largeImageSrc}
            //       alt={`Product Image ${selectedImageIndex + 1}`}
            //       className="max-w-full max-h-full object-center object-contain"
            //     />
            //   </div>

            //   {/* Image gallery (conditionally rendered for screen size) */}
            //   <div className="hidden md:flex md:flex-row gap-4 mt-4 mb-5 overflow-x-auto">
            //     {productImages.map((image, index) => (
            //       <img
            //         key={index}
            //         onClick={() => handleImageClick(index)}
            //         src={`http://localhost:3000/${image.replace(
            //           /^\.\.\//,
            //           ""
            //         )}`}
            //         alt={`Product Image ${index + 1}`}
            //         className={`h-24 w-full object-cover rounded cursor-pointer ${
            //           selectedImageIndex === index
            //             ? "border-2 border-indigo-500"
            //             : ""
            //         }`}
            //         style={{ flexBasis: `calc(25% - 8px)` }}
            //       />
            //     ))}
            //   </div>
            // </div>
          )}

          {/* Product info */}
          <div className="lg:w-1/3">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
              {product.data.name}
            </h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-2xl text-gray-900">₹ {product.data.price}</p>
            </div>

            {/* Reviews */}
            <div className="mt-3">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                {product.data.rating && (
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={product.data.rating}
                      precision={0.5}
                      readOnly
                      size="small"
                    />
                  </Stack>
                )}
              </div>
            </div>

            <form className="mt-6">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2 sm:grid-cols-2">
                <button
                  type="button"
                  className="w-full bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  onClick={(e) => handleAddToCart(e)}
                >
                  <AiOutlineShoppingCart
                    className="h-4 w-4 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Add to Cart
                </button>
                <button
                  type="button"
                  className="w-full bg-indigo-50 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  onClick={(e) => handleAddToWishlist(e)}
                >
                  <CiHeart
                    className="h-4 w-4 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Add to Wishlist
                </button>
              </div>
            </form>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>
            </section>
          </div>
        </div>
        <div className="w-full md:w-1/3 mt-10">
          <p className="text-2xl font-semibold">Customer reviews</p>
          <Box className="mb-2" display="flex" alignItems="center">
            <Rating
              value={4.5}
              readOnly
              precision={0.2}
              size="small"
              className="mr-2"
            />
            <Typography
              variant="body2"
              sx={{
                color: "black",
                fontSize: "17px",
                fontWeight: "bold",
              }}
              className="ml-2"
            >
              4.85 out of 5
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            className="mb-4 pb-3"
          >
            1,745 global ratings
          </Typography>
          {[
            { label: "5 star", value: 70 },
            { label: "4 star", value: 17 },
            { label: "3 star", value: 8 },
            { label: "2 star", value: 4 },
            { label: "1 star", value: 1 },
          ].map((rating, index) => (
            <Box
              key={index}
              className="mb-2"
              display="flex"
              alignItems="center"
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ whiteSpace: "nowrap", mr: 2 }}
              >
                {rating.label}
              </Typography>
              <Box sx={{ flexGrow: 1, mr: 2 }}>
                <LinearProgress
                  variant="determinate"
                  value={rating.value}
                  sx={{
                    height: 10,
                    backgroundColor: "#e0e0e0",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#FFB400",
                    },
                  }}
                />
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ minWidth: 30 }}
              >
                {rating.value}%
              </Typography>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
}
