import {
  Button,
  Input,
  Select,
  Option,
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { AiOutlineSave } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import SideBarNav from "../../UserProfile/SideBarNav";
import ImageUpload from "./ImageUpload";
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProductByIdAsync,
  updateProductAsync,
} from "../../Product/productSlice";

const AdminEditProducts = () => {
  const [name, setName] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [price, setPrice] = useState("");
  const [rating, setRating] = useState("");
  const [type, setType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [category_name, setCategoryName] = useState("");
  const [stock, setStock] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const product = useSelector((state) => state.product.selectedProduct);
  console.log("Product:", product);

  useEffect(() => {
    if (id) {
      dispatch(fetchAllProductByIdAsync(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (product?.success && product.data) {
      const productDetails = product.data;
      console.log("Product details:", productDetails);

      setName(productDetails.name || "");
      setImageSrc(productDetails.imageSrc || "");
      setPrice(productDetails.price || "");
      setRating(productDetails.rating || "");
      setQuantity(productDetails.quantity || "");

      const categoryMapping = {
        "Ayurvedic Remedies": "1",
        "Herbal Personal Care": "2",
        "Health & Wellness Supplements": "3",
      };
      setCategoryName(categoryMapping[productDetails.category_name] || "");

      setStock(productDetails.stock === 1 ? "1" : "2");

      const typeMapping = {
        syrup: "syrup",
        capsules: "capsules",
        "hair-oil": "hair-oil",
        tonic: "tonic",
        supplements: "supplements",
        "face-cream": "face-cream",
      };
      setType(typeMapping[productDetails.type] || "");
    }
  }, [product?.data]);

  const { loading } = useSelector((state) => state.product);

  const handleSubmit = useCallback(
    async (isDraft) => {
      if (
        !name ||
        !imageSrc ||
        !price ||
        !rating ||
        !type ||
        !quantity ||
        !category_name ||
        !stock
      ) {
        alert("Please fill out all required fields.");
        return;
      }

      const productData = {
        name,
        imageSrc,
        price,
        rating,
        type,
        quantity,
        category_id: category_name,
        stock,
        status: isDraft ? "draft" : "published",
      };
      const res = await dispatch(updateProductAsync({ productData, id }));
      if (res.type === "product/updateProduct/fulfilled") {
        alert("Product updated successfully");
        navigate("/admin-products");
      } else {
        alert("Error in updating product");
      }
    },
    [
      name,
      imageSrc,
      price,
      rating,
      type,
      quantity,
      category_name,
      stock,
      dispatch,
      id,
    ]
  );

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${apiUrl}/auth/upload`, {
        method: "POST",
        body: formData,
      });
      console.log("response", response);

      if (!response.ok) {
        throw new Error("Image upload failed");
      }

      const data = await response.json();
      setImageSrc(data.imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="h-auto border-t border-gray-300 flex bg-gray-50">
      <SideBarNav />
      <div className="w-full px-5">
        <Card className="mb-6">
          <CardBody className="flex justify-between items-center">
            <Typography variant="h3" className="font-bold">
              Edit product
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                color="gray"
                className="flex items-center gap-2"
                onClick={() => handleSubmit(true)}
                disabled
                aria-label="Save draft"
              >
                <AiOutlineSave className="text-xl" />
                {loading ? "Saving..." : "Save draft"}
              </Button>

              <Button
                variant="filled"
                color="blue"
                className="flex items-center gap-2"
                onClick={() => handleSubmit(false)}
                disabled={loading}
                aria-label="Publish product"
              >
                <AiOutlineSave className="text-xl" />
                {loading ? "Publishing..." : "Publish product"}
              </Button>
            </div>
          </CardBody>
        </Card>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <Card className="mb-6">
              <CardBody>
                <Typography variant="h4" className="font-bold mb-4">
                  Basic information
                </Typography>
                <div className="flex flex-col gap-4">
                  <Input
                    label={name ? "Name" : "Enter a product name"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    aria-label="Product name"
                  />
                  <div className="flex gap-4">
                    <Select
                      label="Category"
                      value={category_name}
                      onChange={(value) => setCategoryName(value)}
                      aria-label="Product category"
                    >
                      <Option value="1">Ayurvedic Remedies</Option>
                      <Option value="2">Herbal Personal Care</Option>
                      <Option value="3">Health & Wellness Supplements</Option>
                    </Select>
                    <Select
                      label="Product Type"
                      value={type}
                      onChange={(value) => setType(value)}
                      aria-label="Product type"
                    >
                      <Option value="capsules">Capsules</Option>
                      <Option value="face-cream">Face Cream</Option>
                      <Option value="hair-oil">Hair-Oil</Option>
                      <Option value="supplements">Supplements</Option>
                      <Option value="syrup">Syrup</Option>
                      <Option value="tonic">Tonic</Option>
                    </Select>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="mb-6">
              <CardBody>
                <Typography variant="h4" className="font-bold mb-4">
                  Pricing & Inventory
                </Typography>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <Input
                    label={price ? "Price" : "Enter a product price"}
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                    aria-label="Price"
                  />
                  <Input
                    label={quantity ? "Quantity" : "Enter a product quantity"}
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                    aria-label="Quantity"
                  />
                  <Select
                    label="Stock status"
                    value={stock}
                    onChange={(value) => setStock(value)}
                    aria-label="Stock status"
                  >
                    <Option value="1">In stock</Option>
                    <Option value="2">Out of stock</Option>
                  </Select>
                  <Input
                    label={rating ? "Rating" : "Enter a product rating"}
                    type="number"
                    value={rating}
                    onChange={(e) => setRating(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                    aria-label="Rating"
                  />
                </div>
              </CardBody>
            </Card>
          </div>

          <div>
            <Card className="mb-6">
              <CardBody>
                <Typography variant="h4" className="font-bold mb-4">
                  Product images
                </Typography>
                <ImageUpload onUpload={handleImageUpload} />
                {imageSrc && (
                  <div className="mt-4">
                    <img
                      className="object-cover object-center w-full h-40 max-w-full rounded-lg"
                      src={imageSrc}
                      alt="Uploaded product"
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditProducts;
