// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";

// const initialState = {
//   login: JSON.parse(localStorage.getItem("login_local")) || false,
//   userDetail: JSON.parse(localStorage.getItem("userDetails_local")),
//   reload: false,
//   tempToken: JSON.parse(localStorage.getItem("tempToken_local")) || null,
//   users: [],
// };

// export const fetchAllUsers = createAsyncThunk(
//   "userDetails/fetchAllUsers",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axios.get("http://localhost:5001/auth/users");
//       console.log("response.data redux", response);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// const userDetails = createSlice({
//   name: "userDetails",
//   initialState,
//   reducers: {
//     setLogin: (state, action) => {
//       state.login = true;
//       state.token = action.payload.token;
//       state.tempToken = null;
//       state.userDetail = { ...action.payload.userDetail };
//       localStorage.setItem("login_local", true);
//       localStorage.setItem(
//         "userDetails_local",
//         JSON.stringify(state.userDetail)
//       );
//       localStorage.setItem("token_local", JSON.stringify(state.token));
//     },
//     setLogout: (state) => {
//       state.login = false;
//       state.tempToken = null;
//       state.userDetail = null;
//       state.token = null;
//       localStorage.removeItem("login_local");
//       localStorage.removeItem("userDetails_local");
//       localStorage.removeItem("token_local");
//     },
//     setTempToken: (state, action) => {
//       state.tempToken = action.payload;
//       localStorage.setItem("tempToken_local", JSON.stringify(state.tempToken));
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchAllUsers.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchAllUsers.fulfilled, (state, action) => {
//         state.loading = false;
//         state.users = action.payload;
//       })
//       .addCase(fetchAllUsers.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       });
//   },
// });

// export const { setLogin, setLogout, setTempToken } = userDetails.actions;

// export const selectUserDetails = (state) => state.userDetails.userDetail;
// export const selectUsers = (state) => state.userDetails.users;

// export default userDetails.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
  login: JSON.parse(localStorage.getItem("login_local")) || false,
  userDetail: JSON.parse(localStorage.getItem("userDetails_local")),
  reload: false,
  tempToken: JSON.parse(localStorage.getItem("tempToken_local")) || null,
  users: [],
  loading: false,
  error: null,
};

export const fetchAllUsers = createAsyncThunk(
  "userDetails/fetchAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiUrl}/auth/users`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUserAsync = createAsyncThunk(
  "userDetails/addUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/register`, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userDetails = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.login = true;
      state.token = action.payload.token;
      state.tempToken = null;
      state.userDetail = { ...action.payload.userDetail };
      localStorage.setItem("login_local", true);
      localStorage.setItem(
        "userDetails_local",
        JSON.stringify(state.userDetail)
      );
      localStorage.setItem("token_local", JSON.stringify(state.token));
    },
    setLogout: (state) => {
      state.login = false;
      state.tempToken = null;
      state.userDetail = null;
      state.token = null;
      localStorage.removeItem("login_local");
      localStorage.removeItem("userDetails_local");
      localStorage.removeItem("token_local");
    },
    setTempToken: (state, action) => {
      state.tempToken = action.payload;
      localStorage.setItem("tempToken_local", JSON.stringify(state.tempToken));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setLogin, setLogout, setTempToken } = userDetails.actions;

export const selectUserDetails = (state) => state.userDetails.userDetail;
export const selectUsers = (state) => state.userDetails.users;

export default userDetails.reducer;
