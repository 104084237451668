// export function fetchAllProducts() {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await fetch(
//         "http://localhost:5001/auth/fetchAllProducts",
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const data = await response.json();
//       resolve({ data });
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// export function fetchProductById(id) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await fetch(`http://localhost:8080/products?id=${id}`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       const data = await response.json();
//       resolve({ data });
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// export function fetchSearchProduct(query) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await fetch(
//         `http://localhost:8080/products?name=${query}`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const data = await response.json();
//       resolve({ data });
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// export function createProduct(productData) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await fetch("http://localhost:5001/auth/createProduct", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(productData),
//       });
//       const data = await response.json();
//       resolve({ data });
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

const apiUrl = process.env.REACT_APP_API_URL;

export function fetchAllProducts() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${apiUrl}/auth/fetchAllProducts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchProductById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${apiUrl}/auth/fetchProductById?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchSearchProduct(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${apiUrl}/auth/searchProducts?name=${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

export function createProduct(productData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${apiUrl}/auth/createProduct`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

export function updateProduct({ productData, id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${apiUrl}/auth/updateProduct/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteProduct(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${apiUrl}/auth/deleteProduct/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      reject(error);
    }
  });
}
