import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLogin } from "../Counter/userDetailsSlice";
import { setAddToCart } from "../Cart/cartProductSlice";
import { Input, Button, Typography, Card } from "@material-tailwind/react";

const Login = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  axios.defaults.withCredentials = true;

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    // console.log("Form submitted with values:", values);
    axios
      .post(`${apiUrl}/auth/login`, values)
      .then((res) => {
        if (res.data) {
          // console.log("Response from backend:", res.data);
          // localStorage.setItem("tempToken_local", res.data.temp_token);
          // localStorage.setItem("user", values.email);
          // dispatch(setTempToken(res.data.temp_token));
          // navigate("/emailverify");
          const combinedData = res.data.cartItems?.map((cartItem, index) => ({
            cartItem,
            productDetails: res.data.productDetails[index],
          }));
          const productDetailsArray = combinedData?.map(
            (item) => item.productDetails
          );
          // localStorage.removeItem("tempToken_local");
          localStorage.setItem("token_local", res.data.token);
          localStorage.setItem(
            "cart_product",
            JSON.stringify(productDetailsArray)
          );
          dispatch(setAddToCart(combinedData));
          const loginData = {
            token: res.data.token,
            userDetail: res.data.data,
          };
          dispatch(setLogin(loginData));
          navigate("/userprofile");
        } else {
          alert("Login failed");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          alert("Invalid email or password.");
        } else {
          console.log("Error:", err);
        }
      });
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-100 overflow-hidden">
      <Card className="w-full max-w-md p-6">
        <Typography variant="h4" color="blue-gray" className="mb-6">
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <Input
              type="email"
              name="email"
              label="Email"
              size="lg"
              required
              onChange={handleInput}
              className="peer focus:outline-none focus:ring-0"
            />
          </div>
          <div className="mb-6">
            <Input
              type="password"
              name="password"
              label="Password"
              size="lg"
              required
              onChange={handleInput}
              className="peer focus:outline-none focus:ring-0"
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <Link to="/emailConfirm" className="text-blue-600 hover:underline">
              Forgot password?
            </Link>
            <Button type="submit" color="blue" size="lg">
              Login
            </Button>
          </div>
          <Typography variant="small" className="text-center">
            Don't have an account?
            <Link
              to={"/registration"}
              className="ml-1 text-blue-600 hover:underline"
            >
              Sign up &rarr;
            </Link>
          </Typography>
        </form>
      </Card>
    </div>
  );
};

export default Login;
