import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductAsync,
  fetchAllProductsAsync,
  selectAllProducts,
} from "../../Product/productSlice";
import {
  Button,
  IconButton,
  Typography,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Avatar,
  Tooltip,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { HiOutlinePlus, HiOutlineChevronRight } from "react-icons/hi";
import { AiOutlineExport } from "react-icons/ai";
import SideBarNav from "../../UserProfile/SideBarNav";
import { PencilIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";

const AdminProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectAllProducts) || [];
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    dispatch(fetchAllProductsAsync());
  }, [dispatch]);

  const productData = products.success ? products.data : [];

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="h-auto border-t dark:border-blackSecondary border-blackSecondary border-1 flex dark:bg-blackPrimary bg-whiteSecondary">
      <div className="w-64 fixed h-full">
        <SideBarNav />
      </div>
      <div className="dark:bg-blackPrimary bg-whiteSecondary w-full ml-64">
        <Card className="dark:bg-blackPrimary bg-whiteSecondary">
          <CardBody>
            <div className="px-4 sm:px-6 lg:px-8 flex justify-between items-center max-sm:flex-col max-sm:gap-5">
              <div className="flex flex-col gap-3">
                <Typography
                  variant="h3"
                  className="text-3xl font-bold leading-7 dark:text-whiteSecondary text-blackPrimary"
                >
                  All Products
                </Typography>
                <Typography
                  variant="paragraph"
                  className="dark:text-whiteSecondary text-blackPrimary text-base font-normal flex items-center"
                >
                  <span>Dashboard</span>
                  <HiOutlineChevronRight className="text-lg mx-2" />
                  <span>All Products</span>
                </Typography>
              </div>
              <div className="flex gap-x-2 max-[370px]:flex-col max-[370px]:gap-2 max-[370px]:items-center">
                <Button
                  variant="outlined"
                  color="gray"
                  className="flex items-center gap-x-2"
                  disabled
                >
                  <AiOutlineExport className="text-base" />
                  Export
                </Button>
                <Link to="/admin-add-products">
                  <Button
                    variant="filled"
                    color="blue"
                    className="flex items-center gap-x-2"
                  >
                    <HiOutlinePlus className="text-white" />
                    Add a product
                  </Button>
                </Link>
              </div>
            </div>

            <TransactionsTable products={productData} onOpen={handleOpen} />
          </CardBody>
        </Card>
      </div>
      {selectedProduct && (
        <ProductDetailsModal
          open={open}
          product={selectedProduct}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default AdminProducts;

const TABLE_HEAD = ["Product", "Category", "Price", "Status", "Actions", ""];

export function TransactionsTable({ products, onOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    setProductList(products);
  }, [products]);

  const handleDeleteProduct = async (id) => {
    try {
      await dispatch(deleteProductAsync(id)).unwrap();
      setProductList((prevList) =>
        prevList.filter((product) => product.id !== id)
      );
      alert("Product deleted successfully.");
      dispatch(fetchAllProductsAsync());
    } catch (error) {
      console.error("Failed to delete product:", error);
    }
  };

  return (
    <Card className="h-full w-full">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-none"
      ></CardHeader>
      <CardBody className="overflow-visible px-0 relative z-0">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head === "Product" ? (
                      <div className="text-left pl-2">Product</div>
                    ) : (
                      head
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {productList.map(
              (
                { id, name, imageSrc, price, category_name, rating, stock },
                index
              ) => {
                const isLast = index === productList.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Avatar
                          src={imageSrc}
                          alt={name}
                          size="md"
                          className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                        />
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {name}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {category_name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        ₹ {price}
                      </Typography>
                    </td>
                    <td className={`${classes} text-center`}>
                      <div className="flex justify-center items-center w-full h-full">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={stock === 1 ? "In Stock" : "Out of Stock"}
                          color={stock === 1 ? "green" : "red"}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex justify-center items-center gap-3">
                        <Tooltip content="Edit">
                          <IconButton variant="text">
                            <Link to={`/admin-edit-products/${id}`}>
                              <PencilIcon className="h-4 w-4" />
                            </Link>
                          </IconButton>
                        </Tooltip>
                        <Tooltip content="View">
                          <IconButton
                            variant="text"
                            onClick={() =>
                              onOpen({
                                id,
                                name,
                                imageSrc,
                                price,
                                category_name,
                                rating,
                                stock,
                              })
                            }
                          >
                            <EyeIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content="Delete">
                          <IconButton
                            variant="text"
                            onClick={() => handleDeleteProduct(id)}
                          >
                            <TrashIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Button variant="outlined" size="sm">
          Previous
        </Button>
        <div className="flex items-center gap-2">
          <IconButton variant="outlined" size="sm">
            1
          </IconButton>
          <IconButton variant="text" size="sm">
            2
          </IconButton>
          <IconButton variant="text" size="sm">
            3
          </IconButton>
          <IconButton variant="text" size="sm">
            ...
          </IconButton>
          <IconButton variant="text" size="sm">
            8
          </IconButton>
          <IconButton variant="text" size="sm">
            9
          </IconButton>
          <IconButton variant="text" size="sm">
            10
          </IconButton>
        </div>
        <Button variant="outlined" size="sm">
          Next
        </Button>
      </CardFooter>
    </Card>
  );
}

const ProductDetailsModal = ({ open, product, onClose }) => {
  return (
    <Dialog open={open} handler={onClose}>
      <DialogHeader>{product.name}</DialogHeader>
      <DialogBody>
        <div className="flex flex-col items-center">
          <img
            src={product.imageSrc}
            alt={product.name}
            className="w-32 h-32 object-cover mb-4"
          />
          <Typography variant="h6" color="gray" className="mb-2">
            {product.category_name}
          </Typography>
          <Typography variant="h4" color="blue-gray" className="mb-2">
            ₹ {product.price}
          </Typography>
          <Typography color="gray" className="mb-4">
            {product.stock === 1 ? "In Stock" : "Out of Stock"}
          </Typography>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" color="red" onClick={onClose} className="mr-1">
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
